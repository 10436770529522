import {Button, Card, Checkbox, Col, Dropdown, Menu, Row, Table} from "antd";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import {useEffect, useState} from "react";
import useMessageUser from "../../../../../hooks/useMessageUser";
import useFetch from "../../../../../hooks/useFetch";
import {IGroup, IMonitoring} from "../../models";
import {EllipsisOutlined} from "@ant-design/icons";

const ListGroups = ({ setView, setData }: { setView: any, setData: any}) => {

  const initialColumns = [
    {
      title: 'Grupo',
      dataIndex: 'groName',
      key: 'groName',
      visible: true,
    },
    {
      title: 'Acciones',
      dataIndex: 'groId',
      key: 'groId',
      visible: true,
      render: (item: any, row: any) => {
        return(
          <Button type='primary' onClick={() => handleEditRegister(row)}>Editar</Button>
        );
      }
    },
  ];

  const { fetchApi, loading } = useFetch();
  const [dataSource, setDataSource] = useState<Array<IGroup>>([]);
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const { showMessage }  = useMessageUser();

  useEffect(() => {
    getTabla();
  }, []);
  const getTabla = (page = 0) => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/group?page=${page}`,
      data: {},
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const handleEditRegister = async (item: IGroup) => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/group/find_by_id/${item.groId}`
    }
    const { data }: AxiosResponse<ResponseInterface> = await fetchApi(dataSend)
    if (data.status === 'success') {
      setData(data?.data);
      setView('form');
    } else {
      showMessage(data?.message, 'error');
    }
  }

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );
  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  return(
    <Card className='border-radius'>
      <Row>
        <Col span={24}>
          <Table loading={loading} dataSource={dataSource} columns={visibleColumns} scroll={{ x: 1000 }} pagination={false} rowKey='cinId' />
        </Col>
      </Row>
    </Card>
  );
}

export default ListGroups;
