import {
  Badge,
  Breadcrumb,
  Button,
  Card, Checkbox,
  Col, Dropdown,
  Form, Input,
  Layout, Menu,
  Modal,
  Row, Select,
  Space,
  Table, TableProps,
  Tooltip,
  Typography
} from "antd";
import {useEffect, useState} from "react";
import {
  EllipsisOutlined,
  FileExcelOutlined,
  SyncOutlined,
  ExclamationCircleFilled,
  CheckCircleFilled
} from "@ant-design/icons";
import DpaSelected from "../../../../Components/DpaSelected";
import {IMonitoring} from "../../models";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import DrawerProjectC2 from "../../components/DrawerProjectC2";
import useExport from "../../../../../hooks/comms/useExport";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const Compromiso2 = () => {

  const { t } = useTranslation();

  const initialColumns = [
    {
      title: t('code'),
      dataIndex: 'countryDpaCode',
      key: 'countryDpaCode',
      visible: true,
      fixed: 'left',
      sorter: true,
      render: (item: any, row: any) => {
        return(`${row?.puDpaCode}-${row?.lvl4DpaCode}-${row?.lvl8DpaCode || row?.lvl7DpaCode || row?.lvl6DpaCode || row?.lvl5DpaCode || row?.lvl4DpaCode}`);
      }
    },
    {
      title: t('region'),  // Reemplaza 'Región'
      dataIndex: 'regionDpaName',
      key: 'regionDpaName',
      visible: false,
      sorter: true,
    },
    {
      title: t('country'),  // Reemplaza 'País'
      dataIndex: 'countryDpaName',
      key: 'countryDpaName',
      visible: true,
      sorter: true,
    },
    {
      title: t('puCode'),  // Reemplaza 'PUCOD'
      dataIndex: 'puDpaCode',
      key: 'puDpaCode',
      visible: true,
      sorter: true,
    },
    {
      title: t('puName'),
      dataIndex: 'puDpaName',
      key: 'puDpaName',
      visible: true,
      sorter: true,
    },
    {
      title: t('area'),
      dataIndex: 'lvl4DpaName',
      key: 'lvl4DpaName',
      visible: true,
      sorter: true,
    },
    {
      title: t('levelFive'),  // Reemplaza 'Nivel 5'
      dataIndex: 'lvl5DpaName',
      key: 'lvl5DpaName',
      visible: false,
      sorter: true,
    },
    {
      title: t('levelSix'),  // Ya estaba traducido correctamente
      dataIndex: 'lvl6DpaName',
      key: 'lvl6DpaName',
      visible: true,
      sorter: true,
    },
    {
      title: t('status'),
      dataIndex: 'projectCompleted',
      key: 'projectCompleted',
      visible: true,
      sorter: true,
      render: (item: number) => {
        return(
          <>
            {
              (item === 1) ? (<Button type='text' ghost size='small' icon={<CheckCircleFilled style={{ color: '#05CD99' }} />}>{t('completed')}</Button>) : (<Button type='text' ghost size='small' icon={<ExclamationCircleFilled style={{ color: '#FFCE20' }} />}>{t('pending')}</Button>)
            }
          </>
        );
      }
    },
    {
      title: t('actions'),
      dataIndex: 'id',
      key: 'id',
      visible: true,
      sorter: true,
      render: (item: any, row: any) => {
        return(
          <Button
            type='primary'
            onClick={() => checkProjectDrawer(row)}
            style={{ whiteSpace: 'normal', height: 'auto', padding: '8px' }}
          >
            {t('enterInformation')}
          </Button>
        );
      }
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterApply, setFilterApply] = useState<any>({});
  const [filterApplyExcel, setFilterApplyExcel] = useState<any>({});
  const [itemRegister, setItemRegister] = useState<any>({});
  const [dataFilters, setDataFilters] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const { fetchApi, loading } = useFetch();
  const [openModalFilterExport, setOpenModalFilterExport] = useState(false);
  const { showMessage }  = useMessageUser();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });
  const [form] = Form.useForm();
  const { exportExcel, downloading } = useExport();

  useEffect(() => {
    form.setFieldValue('dpasExcel', null);
    setFilterApplyExcel({});
  }, [openModalFilterExport]);

  const checkProjectDrawer = (item: any) => {
    setItemRegister(item);
    setOpenDrawer(true);
  }

  useEffect(() => {
    if (!searchText) {
      const newFilterApply = { ...dataFilters };
      delete newFilterApply.search;
      setDataFilters(newFilterApply);
    }
  }, [searchText]);

  useEffect(() => {
    if (!openDrawer) {
      getTabla();
    }
  }, [dataFilters, openDrawer]);

  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    getTabla(pagination.current, pagination.pageSize, sorter);
  };

  const getTabla = (page = 1, size = 20, sorter: any = '') => {
    const sortParams = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;

    // Transformar filterApply.dpas si no es un array
    const transformedFilterApply = { ...dataFilters };
    if (transformedFilterApply?.dpas) {
      if (!Array.isArray(transformedFilterApply.dpas)) {
        transformedFilterApply.dpas = [{ dpaId: transformedFilterApply.dpas }];
      }
    }

    const dateSend = { ...transformedFilterApply, search: searchText, sort: sortParams };
    if (sorter.order === undefined) {
      delete dateSend.sort;
    }

    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_view_dpa?page=${page - 1}&size=${size}`,
      data: dateSend,
    };

    fetchApi(dataSend).then(({ data }: AxiosResponse<ResponseInterface>) => {
      if (data.status === 'success') {
        const dataResponse = data?.data;
        setDataSource(dataResponse?.content || []);
        setPagination({
          ...pagination,
          current: data?.data?.pageable?.pageNumber + 1,
          total: data?.data?.totalElements,
          pageSize: size,
        });
      } else {
        showMessage(data?.message, 'info');
      }
    }).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value.trim());
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value.trim() }));
    }
  };

  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );

  const onFinish = (values: any) => {
    const dpas = (values?.dpas) ? filterApply?.dpas : null;
    let filters = {...filterApply, dpas, status: values?.status};
    if (!dpas) {
      delete filters?.dpas;
    }
    if (values?.status == false || values?.status == true) {
      filters = { ...filters, status: values?.status }
    }
    setDataFilters(filters);
    setOpenModal(false);
  }

  const cleanFilters = () => {
    setSearchText('');
    form.resetFields();
    setFilterApply({});
    setDataFilters({});
    setOpenModal(false);
  }
  const exportExcelHanddle = async () => {
    if (!filterApplyExcel?.dpasExcel || !form.getFieldValue("dpasExcel")) {
      showMessage('Debe seleccionar una localización para exportar el excel.', 'warning');
      return;
    }
    const url = `/catalogs/projects/donwload_projects/${filterApplyExcel?.dpasExcel || ''}`;
    setOpenModalFilterExport(false);
    const lang = JSON.parse(localStorage.getItem('lng-selected') as string);
    await exportExcel(url, { lang: lang?.lanId || 1 }, 'compromiso_dos');
  }

  const scopes = getScopes();

  return(
    <Layout>
      <Content>
        <Row justify='center'>
          <Col span={24} className='p-1'>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Breadcrumb>
                  <Breadcrumb.Item>{t('planCommitments')}</Breadcrumb.Item>
                  <Breadcrumb.Item>{t('commitment2')}</Breadcrumb.Item>
                </Breadcrumb>
                <Title level={3}>{t('commitment2').toUpperCase()}</Title>
                <Paragraph strong>
                  {t('commitment2Description')}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Card className='border-radius'>
                  <Row gutter={[10, 30]}>
                    <Col span={24}>
                      <Row justify='space-between'>
                        <Col>
                          <Badge dot={(Object.keys(dataFilters)?.length > 0)} >
                            <Button type='primary' ghost icon={ <FileExcelOutlined /> } onClick={() => setOpenModal(true)}>
                              {t('filters')}
                            </Button>
                          </Badge>
                          <Modal width={600} title={t('filters')} open={openModal} onCancel={() => setOpenModal(false)} footer={
                            <Row justify='space-between'>
                              <Col>
                                <Button shape='round' type='primary' danger ghost onClick={cleanFilters}>{t('clearFilters')}</Button>
                              </Col>
                              <Col>
                                <Space>
                                  <Button shape='round' type='text' onClick={() => setOpenModal(false)}>{t('cancel')}</Button>
                                  <Button shape='round' type='primary' onClick={() => form.submit() }>{t('applyFilters')}</Button>
                                </Space>
                              </Col>
                            </Row>
                          }>
                            <Row>
                              <Col span={24}>
                                <Form layout='vertical' form={form} onFinish={onFinish}>
                                  <Row gutter={[10, 0]}>
                                    <Col span={24}>
                                      <DpaSelected form={form} name='dpas' setFilterApply={setFilterApply} filterApply={filterApply} isRequired={false} />
                                    </Col>
                                    <Col span={24}>
                                      <Form.Item name='status' label={t('status')}>
                                        <Select allowClear showSearch>
                                          <Option value={true}><Button type='text' ghost size='small' icon={<CheckCircleFilled style={{ color: '#05CD99' }} />}>{t('completed')}</Button></Option>
                                          <Option value={false}><Button type='text' ghost size='small' icon={<ExclamationCircleFilled style={{ color: '#FFCE20' }} />}>{t('pending')}</Button></Option>
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                          </Modal>
                        </Col>
                        <Col>
                          <Row align='middle' gutter={[20, 20]}>
                            <Col>
                              <Search placeholder={t('searchPlaceholder')} onSearch={handleSearch} allowClear enterButton value={searchText} onChange={(e) => setSearchText(e.target.value) } />
                            </Col>
                            <Col>
                              { dropdown }
                            </Col>
                            <Col>
                              <Tooltip title={t('reloadListTooltip')}>
                                <Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />
                              </Tooltip>
                            </Col>
                            {
                              (scopes.includes('COMP_2_DESCARGAR')) && (
                                <Col>
                                  <Button type='primary' loading={downloading} onClick={() => setOpenModalFilterExport(true)} >{t('exportExcel')}</Button>
                                  <Modal open={openModalFilterExport} title={t('exportExcel')} footer={null} onCancel={() => setOpenModalFilterExport(false)}>
                                    <Row gutter={[10, 10]}>
                                      <Col span={24}>
                                        <Form form={form} layout='vertical'>
                                          <DpaSelected form={form} name='dpasExcel' setFilterApply={setFilterApplyExcel} filterApply={filterApplyExcel} isRequired={true} />
                                        </Form>
                                      </Col>
                                      <Col span={24}>
                                        <Space>
                                          <Button type='primary' ghost danger onClick={() => setOpenModalFilterExport(false)}>{t('cancel')}</Button>
                                          <Button type='primary' onClick={exportExcelHanddle}>{t('export')}</Button>
                                        </Space>
                                      </Col>
                                    </Row>
                                  </Modal>
                                </Col>
                              )
                            }
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        {t('scTableResult')} ({ pagination.total })
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Table dataSource={dataSource} columns={visibleColumns} scroll={{ x: 1200 }} pagination={pagination} onChange={handleTableChange} rowKey="id" />
                    </Col>
                  </Row>
                </Card>
                <DrawerProjectC2 open={openDrawer} setOpenDrawer={setOpenDrawer} item={itemRegister} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Compromiso2;
