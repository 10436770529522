import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
} from "antd";
import useFetch from "../../../../../hooks/useFetch";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import useMessageUser from "../../../../../hooks/useMessageUser";
import { CgGirl } from "react-icons/cg";
import React, {useEffect, useState} from "react";
import { TbMoodBoy } from "react-icons/tb";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {getScopes} from "../../helperComms";

function DetailSc6({ openDetailSc, setOpenDetailSc, data: dataSc, getTabla }: {openDetailSc: boolean, setOpenDetailSc: Function, data: any, getTabla: Function }) {
  const [form] = Form.useForm();
  const scopes = getScopes();
  const { fetchApi } = useFetch();
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [conAbsent, setConAbsent] = useState<boolean>(false);
  const [processQualities, setProcessQualities] = useState<any[]>([]);
  const [inappropriateContent, setInappropriateContent] = useState<any[]>([]);

  useEffect(() => {
    if (openDetailSc) {
      inappropriate();
      Process();
    }
  }, [openDetailSc]);

  useEffect(() => {
    form.resetFields();
    if (dataSc) {
      setConAbsent(dataSc?.conAbsent);
      form.setFieldsValue({
        conReadDate: dataSc?.conReadDate && moment(dataSc?.conReadDate),
        conAbsent: dataSc?.conAbsent || false,
        newDate: dataSc?.newDate && moment(dataSc?.newDate),
        conIncludePhoto: dataSc?.conIncludePhoto || false,
        conAskQuestions: dataSc?.conAskQuestions || false,
        conPersonalLife: dataSc?.conPersonalLife || false,
        conExplainDraw: dataSc?.conExplainDraw || false,
        conScFeelings: dataSc?.conScFeelings || false,
        conPhoto: dataSc?.conPhoto || false,
        conLetter: dataSc?.conLetter || false,
        conFingerprint: dataSc?.conFingerprint || false,
        conScLessFiveYears: dataSc?.conScLessFiveYears || false,
        inappropriateContent: dataSc?.icoId || 1,
        processQualities: dataSc?.pquId || 1,
      });
      if (!dataSc?.conReadDate) {
        form.setFieldValue('conReadDate', moment());
      }
    }
  }, [dataSc]);

  const inappropriate = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/inappropriate-content`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setInappropriateContent(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }
  const Process = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/process-qualities`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setProcessQualities(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const onFinish = (values: any) => {
    // Convertir las fechas a formato "YYYY-MM-DD"
    const formattedValues = {
      ...values,
      scNumber: dataSc?.ascScNumber,
      assId: dataSc?.assId,
      conReadDate: values.conReadDate ? moment(values.conReadDate).format('YYYY-MM-DD') : undefined,
      // conAbsenceDate: values.conAbsenceDate ? moment(values.conAbsenceDate).format('YYYY-MM-DD') : undefined,
    };
    delete formattedValues.newDate;
    /*if (values?.conAbsent === false) {
      delete formattedValues.conAbsenceDate;
    }*/
    // validar que venga en true al menos una de las opciones conPhoto, conLetter, conFingerprint, conScLessFiveYears
    if (!formattedValues.conPhoto && !formattedValues.conLetter && !formattedValues.conFingerprint && !formattedValues.conScLessFiveYears) {
      notification.error({
        message: 'Error',
        description: (
          <div>
            <p>{t('titleOptionRequired')}</p>
            <ul>
              <li>{t('requiredOptionParticipation')} {t('scParticipation')}</li>
            </ul>
          </div>
        ),
      });
      return;
    }

    if (!formattedValues.conIncludePhoto && !formattedValues.conAskQuestions && !formattedValues.conPersonalLife && !formattedValues.conExplainDraw && !formattedValues.conScFeelings) {
      notification.error({
        message: 'Error',
        description: (
          <div>
            <p>{t('titleOptionRequired')}</p>
            <ul>
              <li>{t('requiredOptionParticipation')} {t('valuableDialogue')}</li>
            </ul>
          </div>
        ),
      });
      return;
    }

    const dataSend: FetchApiInterface = {
      url: `/comms/control`,
      data: {
        conMakeSpQuestions: false,
        conIncludePhoto: false,
        conPersonalLife: false,
        conAskQuestions: false,
        conDraw: false,
        conScFeelings: false,
        conExplainDraw: false,
        conActivities: false,
        ...formattedValues,
      },
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          setOpenDetailSc(false);
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      const dataReason = reason?.data || {};
      // Recorremos el objeto de errores y presentamos en una lista de notification
      notification.error({
        message: 'Error',
        description: (
          <div>
            <p>Por favor revisar los siguientes errores:</p>
            <ul>
              {
                Object.keys(dataReason).map((key: any) => (
                  <li key={key}>{key}: {dataReason[key]}</li>
                ))
              }
            </ul>
          </div>
        ),
      });
      showMessage(reason?.message, 'error');
    });
  }

  const calcYear = () => {
    if (!dataSc?.ascScBornDate) return;
    // calculamos la edad en años del SC ' ascScBornDate
    const bornDate = new Date(dataSc?.ascScBornDate);
    const today = new Date();
    const diff = today.getTime() - bornDate.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  const deleteConfirm = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/control/delete`,
      data: [{
        assId: dataSc?.assId,
      }],
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          getTabla();
          setOpenDetailSc(false);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  // Función para deshabilitar las fechas futuras
  const disableFutureDates = (current: any) => {
    return current && current > moment().endOf('day');
  };

  return (
    <Modal
      title={dataSc?.lastModifiedBy ?? ' '}
      open={openDetailSc}
      onOk={() => form.submit()}
      onCancel={() => setOpenDetailSc(false)}
      width={1000}
      okText={t('save')}
      cancelText={t('cancel')}
      footer={[
        <Row justify='space-between'>
          {
            (scopes.includes('COMP_6_ELMINAR')) && (
              <Col>
                <Popconfirm
                  title={`${t('confirmDeleteRecord')} ${dataSc?.ascScName}`}
                  onConfirm={deleteConfirm}
                  okText={t('deleteReport')}
                  cancelText={t('cancel')}
                >
                  <Button type="primary" shape='round' danger>{t('delete')}</Button>
                </Popconfirm>
              </Col>
            )
          }
          <Col>
            <Button type="primary" ghost shape='round' onClick={() => setOpenDetailSc(false)}>{t('cancel')}</Button>
            {
              (scopes.includes('COMP_6_SAVE')) && (
                <Button type="primary" shape='round' onClick={() => form.submit()}>{t('save')}</Button>
              )
            }
          </Col>
        </Row>
      ]}
    >
      <Form form={form} disabled={!scopes.includes('COMP_6_SAVE')} layout='vertical' onFinish={onFinish}>
        <Row gutter={[20, 20]}>
          <Col span={24} md={8}>
            <Card loading={loading} style={{ borderRadius: 10 }}>
              <div style={{ textAlign: 'center', marginBottom: 16 }}>
                {
                  (dataSc?.ascScGenre === 'Female') ? <CgGirl size={80} /> : <TbMoodBoy size={80} />
                }
                <h2>{dataSc?.ascScName}</h2>
                <h3>{calcYear()} {t('yearsOld')}</h3>
                <Divider />
              </div>
              <p><strong>{t('scNumber')}:</strong> {dataSc?.ascScNumber}</p>
              <p><strong>{t('no')}:</strong> {dataSc?.assNo}</p>
              <p><strong>{t('sp')}:</strong> {dataSc?.assSpNumber} </p>
              <p><strong>{t('spName')}:</strong> {dataSc?.assSpName} </p>
              <p><strong>{t('type')}:</strong> {dataSc?.assComType}</p>
              <p><strong>{t('obs')}:</strong> {dataSc?.assObs} </p>
              <p><strong>{t('pu')}:</strong> {dataSc?.ascPuCode}</p>
              <p><strong>{t('community')}:</strong> {dataSc?.ascLevel}</p>
              <p><strong>{t('fdc')}:</strong> {dataSc?.ascCwCode} - {dataSc?.ascCw}</p>
              <Divider />
              <p><strong>{t('modificationDate')}:</strong> {dataSc?.conReadDate}</p>
            </Card>
          </Col>
          <Col span={24} md={16}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Card loading={loading} style={{ borderRadius: 10 }}>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Row gutter={[10, 10]}>
                        <Col span={24} md={12}>
                          <Form.Item
                            name="conReadDate"
                            label={t('readDate')}
                            rules={[{ required: true, message: t('selectDate') }]}
                            initialValue={moment()}
                          >
                            <DatePicker className="w-100" disabledDate={disableFutureDates} format="DD-MM-YYYY" />
                          </Form.Item>
                          <Form.Item
                            name="conAbsent"
                            valuePropName='checked'
                            label={t('absentFromCommunity')}
                          >
                            <Checkbox onChange={(e: CheckboxChangeEvent) => setConAbsent(e.target.checked)}>{t('absent')}</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                          <Form.Item
                            name="newDate"
                            label={t('childDataDueDate')}
                            rules={[{ required: true, message: t('selectDate') }]}
                          >
                            <DatePicker className="w-100" disabled format="DD-MM-YYYY" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                    </Col>
                    <Col span={24} md={12}>
                      <p><strong><span className='text-danger'>* </span> {t('valuableDialogue')}:</strong></p>
                      <Form.Item name="conIncludePhoto" valuePropName='checked'>
                        <Checkbox>{t('includePhoto')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conAskQuestions" valuePropName='checked'>
                        <Checkbox>{t('answersQuestions')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conPersonalLife" valuePropName='checked'>
                        <Checkbox>{t('talksAboutPersonalLife')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conExplainDraw" valuePropName='checked'>
                        <Checkbox>{t('explainsDrawing')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conScFeelings" valuePropName='checked'>
                        <Checkbox>{t('expressesFeelings')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                      <Form.Item rules={[{ required: true, message: t('codeRequired') }]} label={t('inappropriateContent')} name="inappropriateContent">
                        <Select>
                          {
                            inappropriateContent.map((item: any, key: number) => (
                              <Select.Option key={key} value={item?.icoId}>{t(item?.icoText)}</Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                      <Divider />
                      <p><strong><span className='text-danger'>* </span> {t('scParticipation')}:</strong></p>
                      <Form.Item name="conPhoto" valuePropName='checked'>
                        <Checkbox>{t('photo')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conLetter" valuePropName='checked'>
                        <Checkbox>{t('letter')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conFingerprint" valuePropName='checked'>
                        <Checkbox>{t('fingerprint')}</Checkbox>
                      </Form.Item>
                      <Form.Item name="conScLessFiveYears" valuePropName='checked'>
                        <Checkbox>{t('scUnderFiveYears')}</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item rules={[{ required: true, message: t('codeRequired') }]} label={t('processQuality')} name="processQualities">
                        <Select>
                          {
                            processQualities.map((item: any, key: number) => (
                              <Select.Option key={key} value={item?.pquId}>{t(item?.pquText)}</Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default DetailSc6;
